import { style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

export function s(tag: string, css: NestedCSSProperties) {
	const className = style(css);
	return `${tag}.${className}`;
}

export const overlayStyle: NestedCSSProperties = {
	position: "absolute",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%",
};
