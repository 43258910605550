import { RecordingSection } from "good-take/shared/shared-types";

export const state = {
	startTime: 0,
	interval: null as number | null,
	sections: [] as RecordingSection[],
	overlay: {
		accepted: false,
		visible: false,
	}
}
