import m, { Component, redraw } from "mithril";
import { s } from "good-take/shared/shared-styles";
import { state } from "../state";
import { downloadTextFile } from "../helpers/files";
import { padLeft } from "good-take/shared/shared-helpers";
import { RecorderOutput, RecordingSection } from "good-take/shared/shared-types";
import { overlayStyle } from "good-take/shared/shared-styles";

const layout = s("main", {
	position: "relative",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	width: "100%",
	height: "100%"
});

const summary = s("section", {
	textAlign: "center"
})

const acceptedDuration = s("div", {
	fontSize: "6rem",
	fontWeight: "bold",
	margin: "1rem",
});

const controls = s("section", {
	display: "flex",
	justifyContent: "space-evenly",
});

const btn = s("button", {
	padding: "2rem",
	flex: 1,
	fontSize: "2rem",
	fontWeight: "bold",
	border: "none",
	color: "Canvas"
});

const actionOverlay = s("section", {
	...overlayStyle,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	fontSize: "6rem"
});

export const RecorderPage: Component = {
	view() {
		if (state.startTime) {
			return m(layout,
				m(controls,
					m(btn,
						{
							style: { background: "slateblue" },
							onclick: () => stopRecording()
						},
						"Stop"
					)
				),
				m(
					summary,
					m(acceptedDuration, computeAcceptedDuration()),
				),
				m(controls,
					m(btn,
						{
							style: { background: "red" },
							onclick: discardSection
						},
						"Discard"
					),
					m(btn,
						{
							style: { background: "green" },
							onclick: acceptSection
						},
						"Accept"
					),
				),
				actionOverlayView()
			)
		} else {
			return m(layout,
				{ style: { justifyContent: "end" } },
				m(controls,
					m(btn,
						{
							style: { background: "lightblue" },
							onclick: startRecording
						},
						"Start"
					)
				)
			);
		}
	},
}

function actionOverlayView() {
	const {visible, accepted} = state.overlay;
	return visible && m(actionOverlay,
		{
			oncreate() {
				hideOverlay()
			},
			style: {
				backgroundColor: accepted ? "green" : "red"
			}
		},
		accepted ? "✔" : "✘"
	);
}

function hideOverlay() {
	setTimeout(
		() => {
			state.overlay.visible = false;
			redraw();
		},
		1000
	)
}

async function startRecording() {
	state.startTime = Date.now();
	state.interval = window.setInterval(
		() => redraw(),
		1000
	);
	addSection();
}

function getAcceptedSections() {
	return state.sections.filter(s => s.accepted);
}

function stopRecording() {
	const output: RecorderOutput = { sections: state.sections.slice(0, -1) };
	const json = JSON.stringify(output);
	downloadTextFile(json, "recording.json");

	state.sections = [];
	state.startTime = 0;
	if (state.interval) clearInterval(state.interval);
	state.interval = null;
}

function getCurrentSection(): RecordingSection | undefined {
	return state.sections[state.sections.length - 1];
}

function discardSection() {
	state.overlay.visible = true;
	state.overlay.accepted = false;

	addSection();
}

function addSection() {
	const currentTime = Date.now() - state.startTime;
	const currentSection = getCurrentSection();
	if (currentSection) currentSection.endTime = currentTime;
	state.sections.push({
		accepted: false,
		startTime: currentTime,
		endTime: currentTime,
	});
}

function acceptSection() {
	const currentSection = getCurrentSection()!;
	currentSection.accepted = true;

	state.overlay.visible = true;
	state.overlay.accepted = true;

	addSection();
}

function computeAcceptedDuration() {
	const acceptedDurationMillis = getAcceptedSections()
		.map(s => s.endTime - s.startTime)
		.reduce((acc, duration) => acc + duration, 0);
	const currentSection = getCurrentSection();
	const currentDurationMillis = Date.now() - currentSection!.startTime - state.startTime;
	const durationSeconds = Math.round((acceptedDurationMillis + currentDurationMillis) / 1000);
	const seconds = durationSeconds % 60;
	const minutes = Math.floor(durationSeconds / 60);
	return `${padLeft(minutes.toString())}:${padLeft(seconds.toString())}`;
}
